aside {
  height: auto;
}

aside .sidebarAdmin-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;
}

aside .sidebarAdmin-toggle .sidebarAdmin-logo {
  display: flex;
  gap: 0.5rem;
}

aside .sidebarAdmin-toggle .sidebarAdmin-logo img {
  width: 2rem;
  height: 2rem;
}

aside .sidebarAdmin-toggle .csidebarAdmin-lose {
  padding-right: 1rem;
  display: none;
}

aside .sidebarAdmin-sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  height: 100%;
  position: relative;
  top: 1.5rem;
  transition: all 0.3s ease;
}

aside .sidebarAdmin-sidebar:hover {
  box-shadow: none;
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link {
  display: flex;
  align-items: center;
  color: var(--color-info-dark);
  height: 3.7rem;
  gap: 1rem;
  margin-left: 2rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion {
  position: relative;
  margin-left: 2rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion-header,
.sidebarAdmin-accodion-link {
  color: var(--color-info-dark);
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3.7rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion-header span {
  font-size: 1.6rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion-link span {
  font-size: 1.6rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion-header:hover {
  color: var(--color-primary);
}

.sidebarAdmin-accodion-header:hover span {
  margin-left: 0.6rem;
}
.sidebarAdmin-accodion-link:hover {
  color: var(--color-primary);
}

.sidebarAdmin-accodion-link:hover span {
  margin-left: 0.6rem;
}
.sidebarAdmin-link:hover {
  color: var(--color-primary);
}

.sidebarAdmin-link:hover {
  margin-left: 0.6rem;
}
.sidebarAdmin-accodion-content {
  margin-left: 1rem;
  height: 0px;
  overflow: hidden;
  transition: all 0.25s linear;
}
.sidebarAdmin-accodion-content .sidebarAdmin-accodion-link {
  color: var(--color-info-dark);
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3.7rem;
  transition: all 0.3s ease;
}
.sidebarAdmin-accodion-content.is-active {
  height: auto;
}
aside .sidebarAdmin-sidebar .sidebarAdmin-link span {
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link.active {
  width: 100%;
  color: var(--color-primary);
  background-color: var(--color-light);
  margin-left: 0;
  border-radius: 15px;
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link.active::before {
  content: '';
  width: 6px;
  height: 18px;
  background-color: var(--color-primary);
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link.active span {
  color: var(--color-primary);
  margin-left: calc(1rem - 3px);
}
aside .sidebarAdmin-sidebar .sidebarAdmin-link.active {
  width: 100%;
  color: var(--color-primary);
  background-color: var(--color-light);
  margin-left: 0;
  border-radius: 15px;
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link.active::before {
  content: '';
  width: 6px;
  height: 18px;
  background-color: var(--color-primary);
}

aside .sidebarAdmin-sidebar .sidebarAdmin-accodion-link.active span {
  color: var(--color-primary);
  margin-left: calc(1rem - 3px);
}

aside .sidebarAdmin-sidebar .sidebarAdmin-accodion-link:hover {
  color: var(--color-primary);
}

aside .sidebarAdmin-sidebar .sidebarAdmin-accodion-link:hover span {
  margin-left: 0.6rem;
}
aside .sidebarAdmin-sidebar .sidebarAdmin-link:hover {
  color: var(--color-primary);
}

aside .sidebarAdmin-sidebar .sidebarAdmin-link:hover span {
  margin-left: 0.6rem;
}
aside .sidebarAdmin-sidebar .sidebarAdmin-accodion-link.active {
  width: 100%;
  color: var(--color-primary);
  background-color: var(--color-light);
  margin-left: 0;
  border-radius: 15px;
}

aside .sidebarAdmin-sidebar .sidebarAdmin-accodion-link.active::before {
  content: '';
  width: 6px;
  height: 18px;
  background-color: var(--color-primary);
}

aside .sidebarAdmin-sidebar .message-count {
  background-color: var(--color-danger);
  padding: 2px 6px;
  color: var(--color-white);
  font-size: 11px;
  border-radius: var(--border-radius-1);
}
