main .recent-orders {
  margin-top: 1.3rem;
}

.recent-orders::-webkit-scrollbar {
  width: 6px;
  margin-left: 20px;
}
.recent-orders::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.recent-orders::-webkit-scrollbar-thumb {
  background-color: var(--color-primare);
  border-radius: 10px;
  line-clamp: 3;
}
main .recent-orders h2 {
  margin-bottom: 0.8rem;
  margin-top: 50px;
}

main .recent-orders table {
  background-color: var(--color-white);
  width: 100%;
  padding: var(--card-padding);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

main .recent-orders table:hover {
  box-shadow: none;
}

main table tbody td {
  height: 2.8rem;
  border-bottom: 1px solid var(--color-light);
  color: var(--color-dark-variant);
  vertical-align: middle;
}
main table tbody td p {
  max-width: 250px;
  font-weight: 500;
}
main table thead th {
  text-align: start;
}

main table tbody tr:last-child td {
  border: none;
}
main table tbody tr td div p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.project-list td:nth-last-child(2) {
  width: 200px;
}
.project-list td:last-child {
  width: 200px;
}

main .recent-orders a {
  display: block;
  margin: 1rem auto;
  color: var(--color-primary);
}
main .recent-orders h1 {
  margin-bottom: 20px;
}
main .recent-orders .project-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
}
main .recent-orders .project-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
main .recent-orders .project-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
.form-project form {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 15px;
  margin-bottom: 20px;
  display: block;
  grid-column-gap: 10px;
}
.form-project-item label {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 1.2rem;
}
.form-project-item input {
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--color-info-dark);
}
.form-project-item {
  margin-bottom: 30px;
}
.form-project-item input::placeholder {
  opacity: 0.5;
}
.form-project-item textarea {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
}
.form-project-item .error {
  color: red;
  margin-left: 10px;
  margin-top: 10px;
}
.form-project-item button {
  padding: 14px 34px;
  border-radius: 15px;
  background-color: var(--color-info-dark);
  color: var(--color-white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.form-project-item button:hover {
  background-color: var(--color-info-light);
  color: var(--color-dark);
}
.form-project-flex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}
.form-project-item.first-item {
  grid-column: 1/3;
}
.form-project-flex-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.form-project-flex-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.form-project-flex-img {
  position: relative;
  width: 360px;
  height: 200px;
  background-color: var(--color-light);
  border-radius: 15px;
}
.form-project-flex-img p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: var(--color-info-dark);
  color: var(--color-white);
  border-radius: 5px 5px;
  cursor: pointer;
}
.form-project-item select {
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--color-info-dark);
}
.form-project-item select[multiple] {
  height: auto;
  width: 300px;
}
.form-project-item input[type='file'] {
  padding: 14px;
  border-radius: 10px;
  border: 1px solid var(--color-dark);
}
.ql-picker-options {
  max-height: 300px;
  overflow-y: auto;
}
.quill .ql-snow .ql-picker.ql-lineHeight {
  width: 98px;
  display: inline-block;
}
.quill .ql-snow .ql-picker.ql-lineHeight .ql-picker-label {
  width: 100%;
}
.quill .ql-snow .ql-picker.ql-lineHeight .ql-picker-label::before {
  content: 'LHeight ';
}
.quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: 'Size ';
}
.quill .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '';
}
.quill .ql-editor {
  max-height: 500px;
  overflow-y: auto;
}
.project-loading {
  font-size: 20px;
  margin-left: 50px;
}
.project-image {
  width: 200px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 0;
}
.project-image-brand {
  width: 200px;
  height: 120px;
  object-fit: none;
  border-radius: 10px;
  margin: 10px 0;
}
.project-actions {
  transition: all 0.3s ease;
}
.project-actions:hover {
  cursor: pointer;
  color: var(--color-primary);
}
