* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body .wrapper {
  font-size: 15px;
  line-height: 1.3;
  background-color: #ffffff;
  font-family: 'gia-variable', sans-serif;
  font-variation-settings: 'wght' 400;
}
li {
  list-style-type: none;
}
.container {
  width: calc(100%);
  padding: 0 1.38vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1.388vw;
}
.column {
  flex: 1; /* Mỗi cột sẽ chiếm khoảng bằng nhau trong container */
}

/* Định nghĩa độ rộng cho từng loại cột */
.col-1 {
  flex: 0 0 calc(8.33% - 1.27vw);
}
.col-2 {
  flex: 0 0 calc(16.66% - 1.16vw);
}
.col-3 {
  flex: 0 0 calc(25% - 1.043vw);
}
.col-4 {
  flex: 0 0 calc(33.33% - 0.7vw);
}
.col-5 {
  flex: 0 0 calc(41.66% - 0.694vw);
}
.col-6 {
  flex: 0 0 calc(50% - 0.694vw);
}
.col-7 {
  flex: 0 0 calc(58.33%);
}
.col-8 {
  flex: 0 0 calc(66.66% - 0.7vw);
}
.col-9 {
  flex: 0 0 calc(75%);
}
.col-10 {
  flex: 0 0 calc(83.33%);
}
.col-11 {
  flex: 0 0 calc(91.66%);
}
.col-12 {
  flex: 0 0 calc(100%);
}
::-webkit-scrollbar {
  /* display: none; */
  width: 5px; /* Độ rộng của thanh cuộn */
  height: 3px; /* Độ cao của thanh cuộn (dùng cho cuộn ngang) */
}

/* Tùy chỉnh cho phần thanh trượt (thumb) */
::-webkit-scrollbar-thumb {
  background-color: #25b34b; /* Màu sắc của thanh trượt */
  border-radius: 10px; /* Bán kính bo tròn */
  border: 2px solid #25b34b; /* Viền xung quanh thanh trượt */
}

/* Tùy chỉnh cho phần nền của thanh cuộn */
::-webkit-scrollbar-track {
  background: #f4f2ef; /* Màu nền */
}
@media screen and (max-width: 1023px) {
  .container {
    padding: 0 1.955vw;
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 0 2.608vw;
  }
}
@media screen and (max-width: 478px) {
  .container {
    padding: 0 2.5vw;
  }
}
