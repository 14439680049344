.line-views {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.line-table {
  width: 400px;
  border: 1px solid var(--color-light);
  padding: 10px;
  border-radius: 10px;
}
.line-table h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-light);
}
.line-table p {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}
