.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-img {
  opacity: 0;
}
.loading-cricle {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #25b34b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(70px);
}
.loading-inner {
  width: 50%;
  height: 50%;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.loading-inner-2 {
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #ffffff;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.loading-inner-3 {
  position: absolute;
  width: 100%;
  height: 16%;
  background-color: #25b34b;
  top: 50%;
  transform: translateY(-100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 0.13vw 0.13vw 0 0;
}
.loading-inner-4 {
  position: absolute;
  width: 100%;
  height: 35%;
  background-color: #ffffff;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.loading-inner-5 {
  position: absolute;
  width: 33%;
  height: 33%;
  background-color: #25b34b;
  border-radius: 50%;
  top: -5%;
  left: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  animation: animationtop 0.5s 1 1.3s;
}
.loading-inner-6 {
  position: absolute;
  width: 33%;
  height: 33%;
  background-color: #25b34b;
  border-radius: 50%;
  top: -5%;
  right: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  animation: animationtop 0.5s 1 1.3s;
}

@keyframes animationtop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
